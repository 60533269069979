.contact-info {
  text-decoration: none;
  color: var(--primary-color);
}

.appointments-container,
.workingHours-container,
.contacts-container {
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.footer-mobile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5em;
  gap: 1.5em;
  padding-bottom: 8em;
}

.footer-section {
  width: 95%;
}


.footer-lineGradient {
  width: 100%;
  height: 0.1em;
  background: linear-gradient(
    90deg,
    rgba(226, 170, 119, 0) 0%,
    var(--primary-color) 49.08%,
    rgba(226, 170, 119, 0) 100%
  );
}
.footer-lineGradient.first{
  display: none;
}
.footer-section.title {
  font-size: medium;
}
.btn-bookAppointment {
  font-size: large;
  color: var(--primary-color);
  background-color: white;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  width: 50%;
  height: 3em;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workingHours-list {
  list-style: none;
}

.social-media-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3em;
  height: 4em;
  position: fixed;
  background-color: white;
  bottom: 0;
  z-index: 199;
}

.working-hours-container {
  display: flex;
  gap: 1em;
}

.social-icon {
  width: 2.5em;
  height: 2.5em;
  cursor: pointer;
}
.footer-desktop-container {
  display: none;
}
@media screen and (width > 1000px) {
  .footer-lineGradient{
    display: block;
    margin-top: 5em;
  }
  .footer-mobile-container {
    display: none;
  }
  .footer-desktop-container {
    color: var(--primary-color);
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 20em;
    margin-top: 1em;
    border-top: 2px;
    
  }
  .footer-sec {
    height: 70%;
    display: flex;
    flex-direction: column;
    color: white;
  }
  .appointments-container,
  .workingHours-container,
  .contacts-container {
    color: var(--primary-color);
    justify-content: flex-start;
    align-items: left;
    gap: 2em;
  }
  .logoFooter {
    height: 100%;
  }
  .footer-section.title {
    width: 100%;
    font-size: large;
  }
  .media-container {
    width: 100%;
    display: flex;
    gap: 2em;
  }
  .btn-bookAppointment {
    text-decoration: none;
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .btn-bookAppointment:hover {
    background-color: var(--primary-color);
    color: white;
  }
  .footer-lineGradient.first{
    display: block;
  }


  .arrow-footer{
    cursor: pointer;
    color: var(--primary-color);
    position: fixed;
    bottom: 1em;
    right: 1em;
    transition: transform 0.3s ease-in-out;
    animation: jump 0.5s ease-in-out;
  }
  
  @keyframes jump {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .arrow-footer:hover {
    transform: translateY(-5px);
  }
  .visible {
animation-name: jump;  }
}
