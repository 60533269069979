.contact-container {
    display: flex;
    flex-direction: column;
    align-items: top;
    padding: 2em;
    box-sizing: border-box;
    justify-content: center;
    gap : 2em;
    max-width: 1400px;
    margin: 0 auto;
}   

.contact-block {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 2em;
    justify-content: top;
    color: black;
    width: 90%;
    max-width: 350px;
    margin: 0 auto;
}

.map-image {
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    padding: .2em;
    cursor: pointer;
}

@media screen and (width > 1000px) {
    .contact-container {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-top: 6em;
    }
}