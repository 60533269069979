.content-container {
  min-height: 45vh;
  overflow-x: hidden;
}

.main-title {
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
  margin: 1em;
  color: var(--primary-color);
}

.main-title-left {
  font-size: 1.8em;
  font-weight: bold;
  text-align: left;
  margin: 1em;
  color: var(--primary-color);
  margin-left: 0;
}
