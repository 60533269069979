.services-carousel {
  display: flex;
  gap: 2em;
  margin: 0 auto;
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 2em;
  padding-left: 2em;
}

.services-card-title-container {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  border: 1px solid #ffffff;
  font-size: 1.5em;
  height: 3em;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: left;
  bottom: 1em;
  padding: 1em;
}

.services-card-container {
  min-width: 20em;
  width: 20em;
  height: 27em;
  display: flex;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  background-position: center;
  align-items: flex-end;
  padding: 1em;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.services-card-container:hover {
  transform: scale(1.02);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.services-carousel::-webkit-scrollbar {
  height: 0.4em;
}

.services-carousel::-webkit-scrollbar-track {
  background-color: transparent;
}

.services-carousel::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 50px;
}

@media screen and (width > 1200px) {
  .services-carousel {
    display: grid;
    overflow-x: hidden;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 1400px;
  }
}
@media screen and (width > 1400px) {
  .services-carousel {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding-left: 0;
    max-width: 1400px;
  }
}
