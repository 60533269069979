@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: Poppins;
  font-size: small;
}

html, body {
  color: white;
  overflow-x: hidden;
}

:root {
  --primary-color: rgb(226, 170, 119);
  --primary-color: rgb(113, 90, 26);
  --primary-color-transparent : rgba(113, 90, 26, 0.4);
  --primary-color-opaque : rgba(113, 90, 26, 0.8);
  --primary-color: rgb(92, 44, 49);
  --primary-color-transparent : rgba(92, 44, 49, 0.6);
  --primary-color-opaque : rgba(92, 44, 49, 0.8);
}

body::-webkit-scrollbar {
  width: .5em;
}
 
body::-webkit-scrollbar-track {
background-color: transparent
}
 
body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 50px;
}