.header-block {
  background-color: rgb(255, 255, 255);
  cursor: default;
  top: 0px;
  transition: background-color 0.5s ease 0s, box-shadow 0.5s ease 0s;
  width: 100vw;
  z-index: 100;
  position: fixed;
}

.header-container {
  -webkit-box-align: stretch;
  align-items: stretch;
  display: flex;
  height: 90px;
}

.header-logo-container {
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  display: flex;
}
.logo {
  display: block;
  height: auto;
  max-height: 100%;
  width: auto;
  margin: 0.2em 0 0 1em;
  z-index: 1000;
  cursor: pointer;
}
.bokatid-header-btn{
  text-decoration: none;
  color: #fff;
  background-color: var(--primary-color);
  margin-top: 2em;
  height: 3em;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

}
.header-links-container {
  display: flex;
  position: relative;
  display: none;
  gap: .5em;
  padding: .6em;
  
}
.link {
  text-decoration: none;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: fit-content;
  padding: 0.5em 1em 0.5em 1em;
  font-size: 1.2rem;
  user-select: none;
  background-color:  transparent;
  
  color: white;
  border-radius: 5px;
  margin-right: 0.5em;
}
.link.bokatid {
  margin-right: 2em;
  background-color: white;
  color: var(--primary-color);
  border: 1px solid transparent;

}
.link.bokatid:hover {
  background-color: transparent;
  color:white;
  border: 1px solid white;
}
.link:hover {
  background-color: white;
  color: var(--primary-color);
}
.logo-under {
  display: none;
}
@media screen and (min-width: 1000px) {
  .header-block {
    background-color: transparent;
    position: static;
  }
  .header-container {
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    height: 150px;
  }
  .header-links-container {
    display: flex;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      var(--primary-color) 100%
    );
    height: 30%;
    color: var(
      --primary-color
    );
    width: 100%;
    justify-content: end;
    align-items: center;
  }
  .header-line-gradient {
    width: 100%;
    height: 0.15em;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      var(--primary-color) 100%
    );
  }
  .links-line-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .logo-under {
    display: flex;
    position: fixed;
    top: 0;
    width: 20em;
    height: 20em;
    z-index: -100;
  }
  .btn-header-container {
    display: flex;
  }
  .header-hamburger-menu {
    display: none;
  }
}

.wrapper {
  position: fixed;
  top: 60px;
  left: 0; /* Change left value to 0 */
  height: 0; /* Set initial height to 0 */
  width: 100%;
  background: #fff;
  transition: all 0.2s ease-in-out;
  overflow: hidden; /* Add overflow: hidden */
  z-index: 100;
}

#active:checked ~ .wrapper {
  height: 100%; /* Change height to 100% when checked */
}

.menu-btn {
  position: absolute;
  z-index: 2;
  right: 20px;
  /*left: 20px; */
  /* top: 20px; */
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  /*color: #fff;*/
  /*background: linear-gradient(90deg, #f92c78, #4114a1);*/
  /* background: linear-gradient(375deg, #1cc7d0, #2ede98); */
  /* background: linear-gradient(-45deg, #e3eefe 0%, #efddfb 100%); */
  transition: all 0.3s ease-in-out;
}
.menu-btn span,
.menu-btn:before,
.menu-btn:after {
  content: "";
  position: absolute;
  top: calc(90% - 1px);
  left: 10%;
  width: 70%;
  border-bottom: 2px solid #000;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.menu-btn:before {
  transform: translateY(-8px);
}
.menu-btn:after {
  transform: translateY(8px);
}

.close {
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: background 0.6s;
}

/* closing animation */
#active:checked + .menu-btn span {
  transform: scaleX(0);
}
#active:checked + .menu-btn:before {
  transform: rotate(45deg);
  border-color: #000;
}
#active:checked + .menu-btn:after {
  transform: rotate(-45deg);
  border-color: #000;
}
.wrapper {
  display: flex;
  justify-content: center;
}
.wrapper .link-burger-container {
  position: absolute;
  height: 100%;
  list-style: none;
  text-align: center;
  width: 90%; /* Set the width to 100% */
  padding-top: 3em;
}
.wrapper .link-burger-container li:first-child {
  border-top: 1px solid rgb(223, 225, 230);
}

.wrapper .link-burger-container li {
  height: 10%;
  display: flex;
  justify-content: center;
}

.wrapper .link-burger-container li .link-burger {
  border-bottom: 1px solid rgb(223, 225, 230);
  text-decoration: none;
  color: #000;
  position: relative; /* Change position to relative */
  transition: all 0.3s ease;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}
.wrapper .link-burger-container li .link-burger:after {
  position: absolute;
  content: "";
  background: #fff;
  /*background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);*/
  /*background: linear-gradient(375deg, #1cc7d0, #2ede98);*/
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50px;
  transform: scaleY(0);
  z-index: -1;
  transition: transform 0.3s ease;
}
.wrapper .link-burger-container li .link-burger:hover:after {
  transform: scaleY(1);
}

input[type="checkbox"] {
  display: none;
}

.btn-standard-login.burger {
  height: 8%;
  width: 100%;
  border: 1px solid rgb(0, 101, 255);
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-standard-signin.burger {
  height: 8%;
  width: 100%;
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
