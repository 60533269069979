.price-item {
  color: black;
}

.prices-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.prices-table {
  width: 100%;
  border-collapse: collapse;
  max-width: 1000px;
  text-align: left;
  border: 1px solid #444;
  color: black;
  table-layout: fixed;
}

.prices-table > tr > td {
  max-width: 33%;
  border: 1px solid #444;
  padding: 0.2em;
}

.prices-table > th {
  padding: 0.2em;
  max-width: 33%;
  border: 1px solid #444;
}
