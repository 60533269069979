.one-product-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  white-space: pre-wrap;
}

.one-product-button {
  background-color: var(--primary-color);
  text-decoration: none;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 1em;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
}

.one-product-content-container {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 2em auto;
}
