.about-me-text {
  color: black;
}

.about-me-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    box-sizing: border-box;
    justify-content: center;
    gap : 2em;
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
}

.about-me-image {
    margin : auto;
}

@media screen and (width > 600px) {
    .about-me-container {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 0 6em;
    }
}