.first-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-image: url("../../images/landingPic.webp");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 3em;
  -webkit-box-shadow: inset 0px 0px 12px 9px rgba(255, 255, 255, 1);
  -moz-box-shadow: inset 0px 0px 12px 9px rgba(255, 255, 255, 1);
  box-shadow: inset 0px 0px 12px 9px rgba(255, 255, 255, 1);
  margin-top: 8em;
  text-align: center;
}

.first-image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  border: 1px solid grey;
  padding: 2em;
  position: absolute;
  background-color: var(--primary-color-transparent);
  border-radius: 5px;
  border: 1px solid #fff;
  max-width: 40em;
}

.first-image-box h1 {
  font-size: 2em;
  font-weight: 400;
}

.first-image-box-button {
  width: 14em;
  border-radius: 5px;
  height: 3em;
  border: 1px solid #fff;
  background-color: white;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: large ;

}

.first-image-box-button:hover {
  background-color: transparent;
  color: white;
}
@media screen and (width>1000px) {
  .first-image-container{
    margin-top: 0em;

  }
  

}